@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (--viewportMedium) {
    flex-direction: row;
  }


}

.ctaButton {
  background-color: #dc7641 !important;
  color: white !important;
  border: 1px solid #c66a3a !important;
  transition: var(--transitionStyleButton);
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:hover {
    background-color: #c66a3a !important;
  }
}